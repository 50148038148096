import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../../components'
import {
  BioMiniTeaser,
  ClientRender,
  HeroImage,
  LayoutBackground
} from '../../components/shared'
import { getPageBuilderComponents } from '../../components/base/PageBuilderComponents'
import { Seo } from '../../components/base'
import { getLocaleMarket } from '../../utils/getLocaleMarket'
import { BioHeroImage } from '../../components/shared/molecules/BioHeroImage'

const AboutPage = ({ data, pageContext }) => {
  const languages = { pageContext }
  const { title, subtitle, seo, image, miniTeaser, pageBuilder } =
    data.sanityAboutPage.nodes[0]

  let myMarket = getLocaleMarket()

  const layoutBackgroundHeroComponent = <BioHeroImage image={image} />
  return (
    <Layout translations={languages}>
      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.ogImage?.asset?.url}
      />
      <div className="mb-16 xl:mb-32">
        <ClientRender>
          <BioMiniTeaser cards={miniTeaser} />
        </ClientRender>
      </div>
      <div className="relative">
        <LayoutBackground heroComponent={layoutBackgroundHeroComponent}>
          <section className="md:mx-auto">
            <h1 className="text-center">{title}</h1>
            {subtitle != ' ' && (
              <h2 className="text-center bioSubtitle">{subtitle}</h2>
            )}
            {pageBuilder.map((item, index) => (
              <React.Fragment key={index}>
                {getPageBuilderComponents(item, myMarket)}
              </React.Fragment>
            ))}
          </section>
        </LayoutBackground>
      </div>
    </Layout>
  )
}

export default AboutPage

export const aboutQuery = graphql`
  query ($language: String! = "de_de") {
    sanityAboutPage: allSanityAboutPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        i18n_lang
        isNotAvailableInCountry
        title
        subtitle
        seo {
          ...SeoQuery
        }
        image {
          ...ImageObjectQuery
        }
        miniTeaser {
          title
          subline
          url
          urlExtern
          openSidebar {
            marketSearch
            shoppingList
          }
          color
          image {
            ...ImageObjectQuery
          }
          reference {
            ... on SanityLandingPage {
              title
              basePath
              slug {
                current
              }
            }
            ... on SanityPost {
              title
              basePath
              category
              slug {
                current
              }
            }
            ... on SanityNewProductFeaturePage {
              title
              slug {
                current
              }
            }
            ... on SanityCareerPage {
              title
              slug {
                current
              }
            }
          }
        }
        ...AboutPageBuilderElements
      }
    }
  }
`
