import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { ClientRender, DividingBanner, DownloadIcon } from '../shared'
import { Link, navigate } from 'gatsby'
import {
  marketLinkPrefix,
  hasOfferPreview,
  useMarketSearchStore,
  isBetweenDates,
  getCampaignRange,
  getOfferPdf,
  getOfferValidToString
} from '../../utils'
import { getLocale, getLocaleMarketUrl } from '../../utils/localeUtils'

export const OfferHeadlineComponent = ({
  data,
  isPreview,
  market,
  eightPagerGroup,
  publishDates,
  previewCountMarketNoGlobal,
  previewCountGlobal,
  previewCountBmv,
  offerValidTo,
  isCurrentOffer,
  dividingBannerContent
}) => {
  const {
    overviewHeadline,
    overviewSubline,
    overviewSublineMarket,
    downloadButtonText,
    noMarketMessage,
    myMarketLinkLabel,
    offerHandouts
  } = data

  const [previewisVisible, setPreviewIsVisible] = useState(false)

  useEffect(() => {
    setPreviewIsVisible(
      hasOfferPreview(
        market,
        previewCountMarketNoGlobal,
        previewCountGlobal,
        previewCountBmv
      )
    )
  }, [publishDates, market])

  const subline = getOfferValidToString(
    isPreview,
    offerValidTo,
    market,
    overviewSubline,
    overviewSublineMarket
  )
  return (
    <>
      <h1>{overviewHeadline}</h1>
      <p className="px-8" data-testid="valid-to-subline">
        {subline}
      </p>
      {/* <OfferPdf
        buttonText={downloadButtonText}
        marketId={market?.marketId}
        handoutVariant={market?.handoutVariant}
        noMarketMessage={noMarketMessage}
        myMarketLinkLabel={myMarketLinkLabel}
        offerHandouts={offerHandouts}
        isPreview={isPreview}
        isCurrentOffer={isCurrentOffer}
        marketType={market?.marketType}
      /> */}
      <ClientRender>
        <DividingBanner data={dividingBannerContent?.data} />
      </ClientRender>
      <OfferPreview
        visible={previewisVisible}
        market={market}
        isPreview={isPreview}
      />
    </>
  )
}

const OfferPreview = ({ visible, market, isPreview }) => {
  if (!visible || isPreview) return null
  const locale = getLocale()
  return (
    <>
      <br />
      <Link
        className="font-bold md:hover:text-green md:hover:underline"
        to={getLocaleMarketUrl(
          locale,
          market?.countryCode || '',
          `${marketLinkPrefix(market?.slug?.current)}/angebotsvorschau`
        )}>
        Angebotsvorschau
      </Link>
    </>
  )
}

const OfferPdf = ({
  buttonText,
  marketId,
  handoutVariant,
  noMarketMessage,
  myMarketLinkLabel,
  offerHandouts,
  isPreview,
  isCurrentOffer,
  marketType
}) => {
  const handleClick = () => {
    navigate('?market-search=true')
    useMarketSearchStore.setState({
      marketSearchToggled: true
    })
  }
  if (!marketId)
    return (
      <>
        <p data-testid="offer-pdf-download">{noMarketMessage}</p>
        <a onClick={handleClick} className="font-bold cursor-pointer">
          {myMarketLinkLabel}
        </a>
      </>
    )

  const pdfUrl = getOfferPdf(offerHandouts, handoutVariant, marketType)

  //PDF-Download auf Vorschauseite nur aller 2 Wochen zwischen Sa und Di
  const [campaignStart, campaignEnd] = getCampaignRange()
  const dayBeforeOfferStart = dayjs(campaignStart).add(3, 'day')
  const displayPdfForPreview =
    isPreview && isBetweenDates(dayjs(), campaignStart, dayBeforeOfferStart)

  return isCurrentOffer || displayPdfForPreview ? (
    <a
      className="flex mx-auto md:inline-flex justify-center text-md font-bold hover:text-green"
      href={pdfUrl}
      target="_blank"
      //data-offer-pdf={isEightPager ? 'eight-pager' : 'twelve-pager'}
      data-testid="offer-pdf-download"
      rel="noreferrer">
      <span className="w-8 mr-3">
        <DownloadIcon />
      </span>
      {buttonText}
    </a>
  ) : null
}
